$iPhoneXR: 896px;

#steps-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#steps-div {
  display: flex;
  margin: 10px -5px;
  font-size: 15px;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    font-size: 10px;
  }
}

.step-center {
  width: 90px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: black;
  font-weight: 500;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    width: 55px;
    height: 20px;
    line-height: 20px;
    font-weight: 400;
  }
}

.step-center-first {
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: 500;
  color: white;
  background: black;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    width: 60px;
    height: 20px;
    line-height: 20px;
    font-weight: 400;
  }
}

.step-completed {
  background: black;
  color: white;
}

.step-pending {
  background: #e0e0e0;
  color: #999999;
}

.step-right-completed {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 30px solid black;
  border-bottom: 15px solid transparent;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    border-top: 10px solid transparent;
    border-left: 20px solid black;
    border-bottom: 10px solid transparent;
  }
}

.step-right-pending {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 30px solid #e0e0e0;
  border-bottom: 15px solid transparent;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    border-top: 10px solid transparent;
    border-left: 20px solid #e0e0e0;
    border-bottom: 10px solid transparent;
  }
}

.step-left-completed {
  width: 0;
  height: 0;
  border-top: 15px solid black;
  border-left: 30px solid transparent;
  border-bottom: 15px solid black;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    border-top: 10px solid black;
    border-left: 20px solid transparent;
    border-bottom: 10px solid black;
  }
}

.step-left-pending {
  width: 0;
  height: 0;
  border-top: 15px solid #e0e0e0;
  border-left: 30px solid transparent;
  border-bottom: 15px solid #e0e0e0;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    border-top: 10px solid #e0e0e0;
    border-left: 20px solid transparent;
    border-bottom: 10px solid #e0e0e0;
  }
}
