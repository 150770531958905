.custom-chart-tooltip-root{
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid white;
}

.custom-chart-tooltip-id{
    margin-bottom: 10px;
}