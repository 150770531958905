.total-card-root {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  color: #000;
}

.total-card-root:hover {
  background-color: #000;
  color: #fff;
}

.total-card-label {
  font-size: 14px;
}
.total-card-value {
  font-size: 20px;
}
