$iPhoneXR: 896px;

body {
  margin: 0 !important;
  background-color: #ededed !important;
}

.custom-background-color{
  background-color: #ededed !important;
}

.opacity-icon{
  opacity: 0.5;
}

.opacity-icon:hover{
  opacity: 1;
}

.input-100-width {
  width: 100%;
}

@font-face {
  font-family: "SFPro";
  src: local("SFPro"), url("./fonts/SF-Pro.ttf") format("truetype");
}

.body-container {
  padding: 15px;
}

.MuiOutlinedInput-root {
  position: relative !important;
  border-radius: 0.5rem !important;
}

.flex-div {
  display: flex;
  flex-wrap: wrap;
}

.subtitle-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #1c1b1f;
}

.flex-row-components:first-child {
  padding: 10px 5px 10px 0px !important;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    padding: 5px 2px 5px 0px;
  }
}

.flex-row-components:last-child {
  padding: 10px 0px 10px 5px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    padding: 5px 0px 5px 2px;
  }
}

.after-divider {
  margin-top: 15px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.sales-dialog-title {
  font-size: 20px !important;
  text-align: left;
}

.sales-button {
  background-color: transparent !important;
  color: black !important;
  margin: 5px 10px !important;
  &:hover {
    background-color: black !important;
    color: white !important;
  }
}

.flex-align-right {
  justify-content: right;
}

.help-tooltip-icon {
  position: absolute;
  top: -5px;
  right: -5px;
}

.left-black-panel {
  background-color: #000000;
  position: fixed;
  z-index: 9999;
  width: 50vw;
  top: 0px;
  left: 0px;
  height: 100vh;
  overflow: visible;
}

.right-black-panel {
  background-color: #000000;
  position: fixed;
  z-index: 9999;
  width: 50vw;
  top: 0px;
  right: 0px;
  height: 100vh;
}

.no-padding {
  padding: 0 !important;
}
.padding-4px {
  padding: 4px !important;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.rdw-option-wrapper {
  padding: 4px !important;
}

p {
  margin: 0px !important;
}

.text-field-margin-10 {
  padding: 10px !important;
}

.view-html-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.view-html-modal-paper {
  min-height: auto;
  max-height: 80vh;
  width: 40vw;
  overflow: hidden;
  background-color: white;
  padding: 10px;
}

.default-button-outlined-no-hover {
  color: black !important;
  border-color: black !important;
}

.default-button-outlined {
  color: black !important;
  border-color: black !important;
}

.button-outlined-border {
  color: var(--text-color) !important;
  border: 1px solid var(--text-color) !important;
}

.button-outlined-border:hover {
  background-color: var(--reverse-primary-color) !important;
  color: var(--reverse-text-color) !important;
  border: 1px solid var(--reverse-text-color) !important;
}

.default-button-outlined:hover {
  background-color: black !important;
  color: white !important;
}

.default-button {
  background-color: black !important;
}

.default-button:hover {
  background-color: white !important;
  color: black !important;
}

.default-icon:hover {
  background-color: white !important;
  color: black !important;
}

.margin-10 {
  margin: 10px !important;
}

.MuiFilledInput-root {
  background-color: #ffffff !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.MuiFilledInput-root:before {
  border-bottom: 0px !important;
}
.MuiFilledInput-root:after {
  border-bottom: 0px !important;
}

// .MuiTextField-root > div {
//   padding: 0 !important;
// }

// .MuiTextField-root input {
//   padding: 5px 14px 5px 14px !important;
//   font-size: 0.9em;
// }

// .MuiTextField-root textarea:first-child {
//   padding: 5px 14px 5px 14px !important;
//   font-size: 0.9em;
//   height: 20.69px;
// }

// .MuiTextField-root label {
//   top: -4px;
//   font-size: 0.9em !important;
// }

// .MuiTextField-root label[data-shrink="true"] {
//   top: 0;
// }

.icon-button {
  width: 20px !important;
  height: 20px !important;
  margin-left: 5px !important;
  #job-email-editor .rdw-editor-main {
    max-height: 350px;
  }
}

#job-email-editor .public-DraftStyleDefault-block {
  margin: 0 0 0.6em 0 !important;
}

#team-members .MuiAutocomplete-tag {
  height: 22px !important;
}

/* Remove arrows from Input Numbers */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Mui-selected {
  color: black !important;
}
.MuiTabs-indicator {
  background-color: black !important;
}

.split-invoices-grid {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
}

.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

.split-invoice-input-div {
  display: flex;
  margin: 10px;
}

.additional-badge {
  color: #3b3e66;
  background-color: #8eff3e;
}
.additional-badge-wrapper {
  padding: 4px 20px;
}

.additional-badge-border-no-fill {
  border: 1px solid #3b3e66;
  line-height: 17px !important;
}
.additional-black-badge {
  color: #ffffff;
  background-color: #000000;
  line-height: 15px !important;
  font-weight: normal !important;
  text-transform: capitalize !important;
}

.additional-green-badge {
  color: #000000;
  background-color: #8dff3f;
  line-height: 15px !important;
  font-weight: normal !important;
  text-transform: capitalize !important;
}

.additional-badge-wrapper-home {
  padding: 0 12px;
}

.capitalize {
  text-transform: capitalize !important;
}

.percentage-tooltip-icon {
  position: absolute;
  top: -14px;
  right: -20px;
}

.invoice-step-wizard {
  padding: 10px;
  transform: anim-scale(0.94);
  animation: anim-scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

@keyframes anim-scale {
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.question-animation {
  opacity: 0;
  filter: blur(4px);
}
.question-animation:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.question-animation:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.question-animation:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.question-animation:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
.question-animation:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

/* lineup class and keyframes */
.lineUpAnimation {
  animation: 2s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.invoice-splitby-step1-animation {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.add-new-item-btn {
  font-size: 12px !important;
  color: black !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  width: 100%;
}

.drag-drop-icon {
  color: #cfcfcf !important;
  &:hover {
    color: #3b3e66 !important;
  }
}

.endAdornmentInput {
  padding-right: 14px !important;
}

.product-popper {
  > ui:first-child {
    max-height: 94vh !important;
  }
}

.jobline-empty-column {
  width: "5% !important";
  @media (max-width: 1920px) and (orientation: landscape) {
    width: "auto !important";
  }
}

.jobline-total-margin-align {
  @media (min-width: 2000px) and (orientation: landscape) {
    text-align: end !important;
  }
}

.tabs-center-absolute {
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: center;
}

.tabs-main-button {
  position: absolute;
  top: 0;
  right: 0;
}
.position-relative {
  position: relative;
}
.width-100 {
  width: 100%;
}

.paragraph-default {
  display: block !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.edit-user-body-layout {
  display: grid;
  gap: 20px;
}

#upload-to-cloudstorage {
  max-width: 150px;
}

.hyperlink {
  cursor: pointer;
  text-decoration: underline;
}
.hyperlink:hover{
  cursor: pointer;
}
.fullwidth-flex{
  flex: 1;
}