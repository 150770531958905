$iPhoneXR: 896px;

.admin-cards {
  margin: 15px;
  width: 25%;
  background-color: black !important;
  text-align: center;
  justify-content: center;
  cursor: pointer !important;
}

.admin-cards-text {
  font-family: "roboto";
  font-size: 2em !important;
  color: white;
  font-weight: 500;
  margin: 10px;
}

.ipad-tables {
  padding: 0px 20px;
}

.push-notifications {
  margin-bottom: 15px !important;
  width: 100%;
}

.push-notifications-button {
  text-align: right;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

.half-screen-width {
  width: 50%;
  margin-bottom: 15px;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    width: 100%;
  }
}

.push-notification-dialog {
  width: 500px;
  height: auto;
  max-width: unset !important;
  max-height: unset !important;
}

.div-padding-10 {
  padding: 10px;
}

.system-default-div {
  margin: 15px 0px;
}

.edit-user-dialog {
  background-color: #EDEDED !important;
  width: 50vw;
}

.edit-user-dialog .MuiFilledInput-root {
  //background-color: #EDEDED !important;
  // border: 1px solid currentColor;
  // border-radius: 4px;
}
