$iPhoneXR: 896px;

.newcontact-textfield-name {
    width: 50% !important;
    padding: 10px 5px;
  
    @media (max-width: $iPhoneXR) and (orientation: portrait) {
      min-width: 100%;
      padding: 5px 2px;
    }
}

.contact-dialog-container{
  background-color: #EDEDED !important;
}

.contact-dialog-container-title{
  display: flex;
  flex-direction: column;
}

.newcontact-textfield {
    width: 100% !important;
    padding: 10px 5px;
  
    @media (max-width: $iPhoneXR) and (orientation: portrait) {
      min-width: 100%;
      padding: 5px 2px;
    }
}