.company-onboarding-root {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.company-onboarding-checkboxes-payment-day {
  margin-left: 15px;
}

.company-onboarding-checkboxes-payment-day div label {
  height: 25px !important;
}
