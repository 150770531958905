.socialites-items-accordion-container{
    margin: 15px 0px;
}

.socialites-items-accordion-remove{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    path{
        fill: var(--text-color);
    }
}