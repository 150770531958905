// _modes.scss

:root {
    --primary-color: #{$dark-primary-color};
    --secondary-color: #{$dark-secondary-color};
    --text-color: #{$dark-text-color};
    --reverse-primary-color: #{$light-primary-color};
    --reverse-secondary-color: #{$light-secondary-color};
    --reverse-text-color: #{$light-text-color};
    --primary-linear-gradient: #{$primary-linear-gradient};
  }
  
  .dark-mode {
    --primary-color: #{$dark-primary-color};
    --secondary-color: #{$dark-secondary-color};
    --text-color: #{$dark-text-color};
    --reverse-primary-color: #{$light-primary-color};
    --reverse-secondary-color: #{$light-secondary-color};
    --reverse-text-color: #{$light-text-color};
    --primary-linear-gradient: #{$primary-linear-gradient};
  }
  
  .light-mode {
    --primary-color: #{$light-primary-color};
    --secondary-color: #{$light-secondary-color};
    --text-color: #{$light-text-color};
    --reverse-primary-color: #{$dark-primary-color};
    --reverse-secondary-color: #{$dark-secondary-color};
    --reverse-text-color: #{$dark-text-color};
    --primary-linear-gradient: #{$primary-linear-gradient};
  }
  