$iPhoneXR: 896px;

.newcompany-textfield {
  width: 500px !important;
  padding: 10px 5px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 2px;
  }
}

.company-dialog-container{
  background-color: #EDEDED !important;
}

.newcompany-textfield-contact-name {
  width: 250px !important;
  padding: 10px 5px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 2px;
  }
}
.company-dialog-tabs-container{
  display: flex;
  gap: 30px;
  margin-right: 30px;
}
.company-dialog-tabs-button{
  min-width: 200px !important;
}
.company-dialog-tabs-button-active{
  background-color: black !important;
  color: white !important;
}
.newcompany-textfield-help-icon {
  width: 20px !important;
  padding: 10px 5px;
}

.add-company-dialog-company-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.add-company-dialog-height {
  min-height: 50vH;
  max-height: 70vH;
}

.msa-iframe-height {
  min-height: 80vH;
  max-height: 70vH;
}

.msa-iframe-scale-height {
  flex-grow: 1 !important;
  height: 80vh
}

.add-company-dialog-contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.add-company-dialog-accounting-container{
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.add-company-dialog-action-container{
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.add-company-dialog-section-title {
  margin-bottom: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
}

#checkboxes-payment-day {
  margin-left: 15px;
}

#checkboxes-payment-day div label {
  height: 25px !important;
}
