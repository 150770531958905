.date-signed-onboarding-center-header {
    width: 160px !important;
}

.company-onboarding-center-header {
    width: 300px !important;
}

.action-button {
    color: white !important;
    margin: 0px 5px !important;
}