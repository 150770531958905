$iPhoneXR: 896px;

.iframe-div{
    text-align: center;
}

.apexcharts-title-text {
    @media (max-height: $iPhoneXR) and (orientation: portrait) {
        font-size: 15px !important;
      }
}

.apexcharts-toolbar {
    font-family: "roboto";
}