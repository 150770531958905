
.thompson-spencer-loader-wrapper{
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
}
.thompson-spencer-loader-root{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.thompson-spencer-loader-text-container{
    font-family: 'SFPro', 'Roboto';
    font-size: 49px;
    font-weight: 860;
    color: #fff;
}

.thompson-spencer-loader-logo{
    position: absolute;
    z-index: 999;
    justify-content: center;
    align-items: center;
    display: flex;
}
.thompson-spencer-loader-logo img{
    margin: auto;
}
.thompson-spencer-loader{
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
    animation: animate 0.5s linear infinite;
}

@keyframes animate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.thompson-spencer-loader span{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
}

.thompson-spencer-loader span:nth-child(1) {
    filter: blur(15px);
}
.thompson-spencer-loader span:nth-child(2) {
    filter: blur(25px);
}
.thompson-spencer-loader span:nth-child(3) {
    filter: blur(45px);
}
.thompson-spencer-loader span:nth-child(4) {
    filter: blur(80px);
}

.thompson-spencer-loader:after{
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background-color: #000000;
    border-radius: 50%;
}


