.socialites-add-row-root {
  width: 100%;
  background-color: var(--primary-color);
  padding: 30px;
  display: flex;
  justify-content: center;
}
.socialites-add-new-item-btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.socialites-add-new-item-group-label{
  font-weight: 500 !important;
    color: #747474;
    padding: 0 10px;
    opacity: 0.8;
}