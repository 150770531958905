$iPhoneXR: 896px;

.newjob-textfield {
  // width: 500px !important;
  padding: 10px 5px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 2px;
  }
}

.newjob-textfield-name {
  // width: 355px !important;
  padding: 10px 5px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 2px;
  }
}

.newjob-textfield-shootdays {
  width: 160px !important;
  padding: 10px 5px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 2px;
  }
}

.newjob-textfield-date {
  width: 200px !important;
  padding: 10px 5px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 2px;
  }
}

.po-textfield-container {
  padding: 10px 5px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 2px;
  }
}

.highlight-border-light-blue {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(81, 203, 238, 1);
}

.highlight-border-red {
  box-shadow: 0 0 5px rgb(162, 19, 19);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgb(162, 19, 19);
}

.job-retainer-container {
  display: flex;
  gap: 10px;
  padding: 10px 0px;
}

.job-retainer-container input {
  width: 100% !important;
  min-width: 100% !important;
}

.row-textbox-table {
  padding: 10px 0px 10px 0px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 0px 5px 0px;
  }
}

#jobs-gauge-container {
  width: 250px;
  position: fixed;
  bottom: 15px;
  left: 15px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    width: 150px;
    bottom: 10px;
    left: 10px;
  }
}

#gauge-text-info {
  text-align: center;
}

#gauge-text-info-typography {
  font-size: 22px;
  color: black;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    font-size: 15px;
  }
}

.newjob-textfield-jobdescription {
  width: 100% !important;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    min-width: 100%;
    padding: 5px 0px 5px 0px;
  }
}

.jobdescription-text-editor-wrapper {
  background-color: white;
  border-width: 0px 1px 1px 1px;
  margin-top: 0px !important;
  padding-top: 0px !important;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23);

  .rdw-editor-toolbar {
    border: 0px !important;
    border-top: 1px solid #d1d2db !important;
    padding-bottom: 0px !important;
  }

  .rdw-option-wrapper {
    border: 0px !important;
  }

  .rdw-dropdown-wrapper {
    border: 0px !important;
  }

  img {
    width: 11px;
  }
}

.jobdescription-text-editor {
  padding: 15px !important;
}

.jobs-footer {
  position: fixed;
  display: flex;
  bottom: 0px;
  right: 0;
  margin-top: auto;
  z-index: 999;

  // height: 185px;
  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    height: 110px;
  }
}

.job-button {
  background-color: black !important;
  color: white !important;
  margin: 10px 5px !important;

  &:hover {
    background-color: white !important;
    color: black !important;
  }

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    font-size: 10px !important;
  }
}

#job-footer-buttons {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  width: -webkit-fill-available;
  margin-bottom: 10px;
}

.job-bottom-spacing {
  margin-top: auto;
  height: 185px;

  @media (max-width: $iPhoneXR) and (orientation: portrait) {
    height: 110px;
  }
}

.jobLineItem th {
  padding: 5px 15px !important;
}

.production-table-header {
  th {
    background-color: hsl(0, 0%, 93%) !important;
    color: black;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-bottom: unset !important;
    border-top: unset !important;
  }

  td {
    border-top: unset !important;
    border-bottom: unset !important;
  }
}

.production-job-lines {
  td {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }

  td:first-child {
    padding-top: 0px !important;
  }
}

.production-job-lines tbody > div:nth-of-type(odd),
.production-job-lines tbody > tr:nth-of-type(odd),
.production-job-lines tbody > tr:nth-of-type(odd) table tbody tr {
  background-color: #e1e1e1;
}

.production-job-lines tbody > div:nth-of-type(even),
.production-job-lines tbody > tr:nth-of-type(even),
.production-job-lines tbody > tr:nth-of-type(even) table tbody tr {
  background-color: #e1e1e1;
}

.production-job-lines tbody tr {
  border-bottom: 0.5px solid #fff;
}

.production-job-lines tbody tr:last-child {
  border-bottom: 0.5px solid white;
}

.production-job-lines th,
.production-job-lines td {
  border-top: unset !important;
  border-bottom: unset !important;
}

.socialites-job-lines {
  background-color: #f6f6f6;
}

// .socialites-job-lines tbody>div:nth-of-type(odd),
// .socialites-job-lines tbody>tr:nth-of-type(odd),
// .socialites-job-lines tbody>tr:nth-of-type(odd) table tbody tr {
//   background-color: #e1e1e1;
// }

// .socialites-job-lines tbody>div:nth-of-type(even),
// .socialites-job-lines tbody>tr:nth-of-type(even),
// .socialites-job-lines tbody>tr:nth-of-type(even) table tbody tr {
//   background-color: #e1e1e1;
// }

.socialites-job-lines th,
.socialites-job-lines td {
  border-top: unset !important;
  border-bottom: unset !important;
}

.add-new-line-button-icon {
  font-size: 25px !important;
  color: black;
  margin-top: -15px;
  margin-left: -9px;
  z-index: 999;
}

.category-name {
  color: #3b3e83;
  width: 82.5%;
  align-self: center;

  @media (max-width: 1980px) {
    width: calc(100% - 360px);
  }

  @media (max-width: 1768px) {
    width: calc(100% - 344px);
  }

  @media (max-width: 1680px) {
    width: calc(100% - 320px);
  }
}

.category-typography {
  font-size: 0.9em !important;
  //font-weight: bold !important;
}

.category-total {
  width: 4%;
  padding: 8px;
  text-align: right;
  align-self: center;
}

.category-margin-total {
  width: 5%;
  padding: 8px;
  text-align: right;
  align-self: center;
}

.grand-total {
  margin-top: 20px;
  display: flex;
  //width: calc(100% - 139px);
  font-size: 1.2em;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  justify-content: right;
  // @media (max-width: 1496px) and (orientation: landscape) {
  //   width: 1364px !important;
  // }
}

.exchanged-grand-total {
  display: flex;
  //width: calc(100% - 139px);
  font-size: 1.2em;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  justify-content: center;
  width: fit-content;
  min-width: 400px;
  background: #e1e1e1;
  // @media (max-width: 1496px) and (orientation: landscape) {
  //   width: 1364px !important;
  // }
}
.cross-organisation-total-remaining-container {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  padding-left: 5px;
  font-size: 1.2em;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  justify-content: center;
  width: fit-content;
  min-width: 400px;
  background: #e1e1e1;
  // @media (max-width: 1496px) and (orientation: landscape) {
  //   width: 1364px !important;
  // }
}

.itemised {
  font-size: 10px !important;
  background-color: hsl(60, 100%, 90%);
  color: black;
}

.description-jobline-th {
  width: 27% !important;

  @media (max-width: 1980px) {
    width: 26% !important;
  }

  @media (max-width: 1496px) {
    width: 353px !important;
  }
}

.quote-preview-dialog {
  width: 95vw;
  height: 95vh;
  max-width: unset !important;
  max-height: unset !important;
}

.iframe-scale-height {
  flex-grow: 1 !important;
}

.job-lines-table-row-header {
  display: grid !important;
  grid-template-columns: 0.04fr 0.21fr 0.1fr 0.25fr 0.07fr 0.07fr 0.05fr 0.07fr 0.07fr 0.03fr !important;

  @media screen and (max-width: 1383px) {
    grid-template-columns: 0.04fr 0.3fr 0.12fr 0.33fr 0.07fr 0.07fr 0.05fr 0.07fr 0.07fr 0.03fr !important;
  }

  @media screen and (max-width: 1333px) {
    grid-template-columns: 0.05fr 0.31fr 0.13fr 0.35fr 0.07fr 0.07fr 0.05fr 0.07fr 0.07fr 0.03fr !important;
  }

  @media screen and (max-width: 1268px) {
    grid-template-columns: 0.05fr 0.32fr 0.14fr 0.37fr 0.06fr 0.07fr 0.05fr 0.07fr 0.07fr 0.03fr !important;
  }

  @media screen and (max-width: 980px) {
    grid-template-columns: 0.05fr 0.36fr 0.2fr 0.35fr 0.07fr 0.07fr 0.05fr 0.07fr 0.07fr 0.03fr !important;
  }
}

.job-lines-table-row {
  display: grid;
  grid-template-columns: 0.04fr 0.25fr 0.1fr 0.3fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.03fr;
  gap: 10px;
  align-items: center;

  @media screen and (max-width: 1218px) {
    grid-template-columns: 0.04fr 0.5fr 0.2fr 0.6fr 0.14fr 0.14fr 0.14fr 0.14fr 0.14fr 0.06fr;
  }
}

.socialites-job-lines-table-row {
  display: grid;
  grid-template-columns: 0.04fr 0.26fr 0.3fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.05fr;
  gap: 10px;
  align-items: center;
}

.job-line-table {
  @media (max-width: 1440px) {
    max-width: 1440px !important;
  }
}

.confirmation-with-input-dialog {
  background-color: #ededed !important;
  width: 50vw;
}

.caption-text {
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.edit-markup-text {
  color: #0055c6;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.edit-markup-text:hover {
  cursor: pointer;
}

.jobline-cell-booking-fee-container {
  display: grid;
  padding: 4px 10px;
  background: white;
  align-items: center;
  justify-items: start;
  color: #808080;
  cursor: pointer;
}

.jobline-cell-div-container {
  height: 80px;
}

.jobline-cell-div-container-display {
  height: 50px;
  text-align: center;
  vertical-align: middle;
}

.jobline-cell-div-container-display-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.jobline-cell-div-container-icon {
  height: 70px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

#cost-price-label {
  font-size: 1em !important;
}

#cost-price-label {
  font-size: 1em !important;
}

.badge-notification-retainer-ending-soon {
  max-width: 25px;
  background-color: #7f062f;
  border-color: #7f062f;
  border-radius: 50px;
  padding: 3px 15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.magnesium-action-button-container {
  max-width: 100px;
}
.check-job-dialog-title-container {
  background: var(--primary-color);
  color: var(--text-color);
  path {
    color: var(--text-color);
  }
}
