.jobs-table {
  margin-top: 30px;
}

#jobs-title {
  color: black !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

#jobs-title-div {
  text-align: left;
}

#new-job-button-div {
  text-align: right;
}

#new-job-button {
  background-color: black !important;
  padding: 10px 20px !important;
  font-size: 15px !important;
}

#new-job-button:hover {
  background-color: white !important;
  color: black !important;
}

.approved-job-row {
  background-color: #86e57b !important;
}

.month-color-january {
  background-color: #FFFFCC !important;
}
.month-color-february {
  background-color: #FFE5CC !important;
}
.month-color-march {
  background-color: #CCFFCC !important;
}
.month-color-april {
  background-color: #E5FFCC !important;
}
.month-color-may {
  background-color: #CCFFE5 !important;
}
.month-color-june {
  background-color: #CCFFFF !important;
}
.month-color-july {
  background-color: #E5CCFF !important;
}
.month-color-august {
  background-color: #CCCCFF !important;
}
.month-color-september {
  background-color: #E5CCFF !important;
}
.month-color-october {
  background-color: #FFCCFF  !important;
}
.month-color-november {
  background-color: #FFCCE5  !important;
}
.month-color-december {
  background-color: #FFCCCC !important;
}

.sorting-header {
  > svg {
    margin-left: 0;
    margin-right: 0;
  }
}

.home-default-button{
  height: 37px; 
  background-color: #86e57b !important; 
  color: black !important;
}