.accounting-card-location-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.accounting-card-register-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.accounting-card-location-payment-terms-div {
  display: flex;
  flex-direction: column;
  position: relative;
}

.accounting-card-register-lookup-container{
  position: relative;
}
.accounting-card-register-lookup-input{
  width: 100% !important;
}
.accounting-card-location-payment-terms-title{
  display: flex;
  gap: 20px;
}
