.new-product-container {
  background-color: #ededed !important;
}
.product-categories-table-root {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #f8f8f8 !important;
}
.product-table-root {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #f8f8f8 !important;
}

.product-category-accordion-root{
  display: grid;
  gap: 5px
}
.product-category-accordion-container{
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  background: #ffffff !important;
}

.product-category-accordion-title{
  padding: 15px 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 600 !important;
  }
}
.product-category-accordion-body{
  padding: 30px;
}
.products-category-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}