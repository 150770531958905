.revenue-dashboard-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.revenue-dashboard-totals {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.revenue-dashboard-table-link {
  display: flex;
  gap: 5px;
  cursor: pointer;
}
.revenue-dashboard-table-link:hover {
  text-decoration: underline;
}


