.socialites-accordion-item-item-container {
  padding: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  align-items: center;
  gap: 30px;
}

.socialites-accordion-item-total-header-container {
  display: grid;
  grid-template-columns: 0.8fr 0.1fr 0.1fr;
  align-items: center;
  gap: 30px;
}

.socialites-accordion-item-total-header-foreign-container {
  align-items: start;
}

.socialites-accordion-item-expanded-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  path{
    fill: var(--text-color);
  }

}

.socialites-accordion-item-total-header-input-container {
  display: flex;
}

.quote-category-text-field {
  input {
    color: black !important;
  }

  .Mui-disabled {
    opacity: unset !important;
    -webkit-text-fill-color: unset !important;
    color: unset !important;
  }

  .MuiFormLabel-root {
    font-size: small;
  }

  .MuiFilledInput-root {
    background-color: var(--primary-color) !important;
  }

  .MuiFilledInput-input {
    font-size: small;
    text-transform: uppercase !important;
    text-decoration: solid !important;
    font-weight: bold !important;
  }
}

.socialites-accordion-item-total-header-input {
  width: 100%;
}

.socialites-accordion-item-name-input-container{
  border: 1px solid var(--text-color);
}

.socialites-accordion-item-name-input input {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.socialites-accordion-item-foreign {
  display: grid;
  grid-template-columns: 1fr;
}

.socialites-accordion-item-totalmargin {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.socialites-accordion-item-description-textfield-description {
  //padding: 20px 54px;
}

.socialites-accordion-item-description-text-editor-wrapper {
  background-color: white !important;
  border-width: 0px 1px 1px 1px !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.23) !important;

  .rdw-editor-toolbar {
    border: 0px !important;
    border-top: 1px solid #d1d2db !important;
    padding-bottom: 0px !important;
  }

  .rdw-option-wrapper {
    border: 0px !important;
  }

  .rdw-dropdown-wrapper {
    border: 0px !important;
  }

  img {
    width: 11px;
  }
}

.socialites-accordion-item-description-text-editor {
  padding: 0px 15px 15px 15px !important;
}

.socialites-service-header-container {
  display: grid;
  grid-template-columns: 0.04fr 0.26fr 0.3fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.05fr;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--text-color);
}

.socialites-service-grandtotal-container {
  display: grid;
  grid-template-columns: 0.04fr 0.26fr 0.3fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.05fr;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.socialites-category-description-container {
  display: grid;
  grid-template-columns: 0.04fr 0.26fr 0.3fr 0.07fr 0.07fr 0.07fr 0.07fr 0.07fr 0.05fr;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.socialites-accordion-item-totalline {
  align-items: center;
  text-align: center;
}