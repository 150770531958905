.dashboard-root {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.dashboard-charts-container {
  width: 100%;
}

.dashboard-reset-btn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px 0px;
}

.dashboard-charts-500-height {
  height: 500px;
}
.dashboard-charts-400-height {
  height: 400px;
}

.dashboard-charts-300-height {
  height: 300px;
}
.dashboard-charts-200-height {
  height: 200px;
}
