.dashboard-filters-root{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}
.dashboard-filters-input {
    width: 100%;
}
.dashboard-filters-input-option{
    min-height: 30px;
}