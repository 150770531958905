.sales-margin-dashboard-root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.sales-margin-dashboard-totals {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
