.login-style {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.login-access-denied-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.google-signin {
  top: 50% !important;
  background-color: black !important;
  color: white !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  font-family: 'roboto';
}
