.invoice-table-root th,
td {
  font-size: 12px !important;
}
.invoice-table-job-name {
  max-width: 200px;
  font-weight: 700;
  padding: 0px 10px !important;
}
.invoice-table-company-name {
  padding: 0px 10px !important;
}
.invoice-table-po-number {
  cursor: pointer;
  display: flex;
  justify-content: center;
  svg {
    fill: red;
  }
}
.invoice-table-align-right {
  text-align: end;
}
.invoice-table-align-center {
  text-align: center;
}

.invoices-table-add-po-number-btn {
  border-radius: 50px !important;
  color: black !important;
  font-size: 12px !important;
}

.invoice-filters-root{
  padding: 15px 0px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

#invoice-month-select-cell .MuiFilledInput-root {
  background-color: unset !important;
}

.invoice-filters-chip-container{
  padding: 5px 0px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}
